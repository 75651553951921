a {
	text-decoration: none;
}
ul {
	margin: 0;
	padding: 0;
	list-style: none;
}
.App {
	height: 100vh;
	overflow: hidden;
}
.input-style {
	background-color: #f2f2f2;
	border: 1px solid #f2f2f2 !important;
	border-radius: 6px;
	height: 62px !important;
	/* max-height: 600px !important; */
}
.MuiAutocomplete-inputRoot {
	background-color: #f2f2f2;
	border: 1px solid #f2f2f2 !important;
	border-radius: 6px;
	/* max-height: 600px !important; */
}
.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
	/* border-color:#f2f2f2 !important ;
	border-radius: 6px; */
	display: none;
}
#search-icon {
	background-image: url('/public/images/search.png');
	background-repeat: no-repeat;
	background-position: 12px 17px;
}
.css-1itqzpn-MuiButtonBase-root-MuiChip-root {
	justify-content: space-around !important;
}
.css-jxgnti-MuiDataGrid-root {
	border: unset !important;
}
.css-jxgnti-MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.css-jxgnti-MuiDataGrid-root .MuiDataGrid-cell:focus {
	/* outline: solid #000000 1px; */
	outline: unset !important;
}
/* .css-xmyr3c-MuiButtonBase-root-MuiButton-root:hover {
	background-color: black !important;
	color: white !important;
} */
.css-jxgnti-MuiDataGrid-root .MuiDataGrid-columnSeparator--resizable {
	opacity: 0 !important;
}
.menu-actions .hide-checkout {
	opacity: 0 !important;
	display: none !important;
}
.menu-actions .hide-pending {
	opacity: 0 !important;
	display: none !important;
}
/* .css-14dy5u4-MuiAutocomplete-root {
	overflow: scroll;
	height: 10px;
}
.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
	max-height: unset !important;
} */
/* .MuiAutocomplete-popper {
} */
/* .MuiAutocomplete-hasPopupIcon.css-14dy5u4-MuiAutocomplete-root .MuiOutlinedInput-root,
.MuiAutocomplete-hasClearIcon.css-14dy5u4-MuiAutocomplete-root .MuiOutlinedInput-root {
	padding-right: 70px !important;
} */

/* .css-1rndd9j-MuiPopper-root-MuiAutocomplete-root {
	 height: 10px !important;
	inset: 0 50px 50px 0px !important;
	max-width: 20px !important;
	background-color: red; 
	z-index: 1500;
} */
.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
	position: unset !important;
}
/* .MuiDataGrid-root .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer {
	display: none;
}
.css-f3jnds-MuiDataGrid-columnHeaders {
	border-top-right-radius: 15px !important;
	border-top-left-radius: 15px !important;
	background: #dadada !important;
} */
.css-dd2h8b-MuiAutocomplete-root .MuiOutlinedInput-root {
	padding: unset !important;
}
