body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* width */

.profile ::-webkit-scrollbar,
.custom-grid-class ::-webkit-scrollbar {
	width: 12px;

	/* background: transparent;
	width: 8px;
	height: 0px; */
}

/* Track */
::-webkit-scrollbar-track {
	/* background: #f1f1f1; */
	border-radius: 50px;
	height: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #aaa;
	border-radius: 50px;

	/* border-radius: 50px;
	height: 120px; */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}
/* ::-webkit-scrollbar-button {
	height: 100px;
} */
